"use client";

import * as Sentry from "@sentry/nextjs";

export default function Page() {
  return (
    <div className="my-2">
    <button
      className="text-white bg-black p-2 rounded"
      type="button"
      onClick={() => {
        Sentry.startSpan({
          name: 'Example Frontend Span',
          op: 'test'
        }, async () => {
          const res = await fetch("/api/sentry-example-api");
          if (!res.ok) {
            throw new Error("Sentry Example Frontend Error");
          }
        });
      }}
    >
      Sentry
    </button>
    </div>
  );
}
